
import { defineComponent } from "@vue/runtime-core";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import firebase from "firebase/compat/app";
import { auth } from "@/services/firebase";
import { getErrorMessage } from "@toppick/common/build/utils";
import HeaderSection from "./HeaderSection.vue";
import "../styles.css";

export default defineComponent({
  components: { HeaderSection },
  data() {
    return {
      isLoading: false,
      stayConnected: true,
      error: "" as string,
      success: "" as string,
      email: "" as string,
      password: "" as string,
    };
  },
  methods: {
    validDateForm() {
      if (!this.email || !this.password) {
        throw new Error("Fill all fields");
      }
    },
    async signIn(): Promise<void> {
      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);
      } catch (error) {
        throw new Error("Invalid Credentials");
      }
    },
    async onGoogleSignIn() {
      try {
        this.isLoading = true;
        this.error = "";
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        await signInWithPopup(auth, provider);
        this.success = "Successfully signed in!";
        setTimeout(() => {
          this.$router.push({
            name: "Home",
          });
        }, 1000);
      } catch (error) {
        this.error = "Failed to sign in with Google";
      }
      this.isLoading = false;
    },
    async onSubmit() {
      this.isLoading = true;
      this.error = "";
      try {
        this.validDateForm();
        await this.signIn();
        if (!this.stayConnected) {
          await auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
        }
        this.success = "Successfully logged in!";
        setTimeout(() => {
          this.$router.push({
            name: "Home",
          });
        }, 1000);
      } catch (error) {
        this.error = getErrorMessage(error);
      }
      this.isLoading = false;
    },
  },
  computed: {},
});
