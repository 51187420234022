
import { resetPassword } from "@toppick/common/build/api/user";
import { getErrorMessage } from "@toppick/common/build/utils";
import { validateEmail } from "@toppick/common/build/validators";
import { defineComponent } from "@vue/runtime-core";
import HeaderSection from "./HeaderSection.vue";
import { event } from "vue-gtag";
import "../styles.css";
export default defineComponent({
  components: { HeaderSection },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      error: "" as string,
      email: "" as string,
      emailSentSvg: require("@/assets/images/email-sent.svg"),
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      this.isSuccess = false;
      this.error = "";
      try {
        if (!validateEmail(this.email)) {
          throw new Error("Invalid email provided");
        }
        event("password-reset");
        await resetPassword(this.email);
        this.isSuccess = true;
      } catch (error) {
        console.log(error);
        this.error = getErrorMessage(error);
      }
      this.isLoading = false;
    },
  },
});
